<template>
    <div class="mainwindow">
        <!-- step 1-->
        <div class="emailForm" v-if="step==1">
            <div class="formheader">
                {{title}}
            </div>
            <div class="emptyline"></div>

            <el-form ref="form"
                     :model="form"
                     :rules="rules">
                      <el-form-item class="account-box" prop ="email" :rules="rules">
                        <el-input  :placeholder="emailPlaceholder" v-model="form.email" clearable>
                          <i slot="prefix" class="iconfont icon-yonghuming"></i>
                        </el-input>
                      </el-form-item>
                <div class="step1-mid">
                    <div class="bottomnotice">{{noticetext}}</div>
                </div>
                <el-button type="primary" class="step3-button" @click.native="doReset()">{{buttonText}}</el-button>
                <div class="step3-bottom">
                    <div class="bottonnotice">{{noticeText1}}</div>
                    <router-link to="/login" class="link" style="margin-left: 20px" replace>{{loginText}}</router-link>
                </div>
            </el-form>
        </div>
        <!-- step 2-->
        <div class="emailForm" v-if="step==2">
            <div class="formheader">
                {{mailtitle}}
            </div>
            <div class="emptyline"></div>
            <div class="verifyline">
                <div class="icon" ></div>
                <div class="content">
                    <div class="eline">
                        <div class="emailinfo">{{emailLabel}}</div>
                        <div class="emailinfo" style="margin-left: 12px">{{form.email}}</div>
                    </div>

                    <div class="nodity">{{mailNotifyContent}}</div>
                    <div class="clickedinfo">{{clickinfo}}</div>
                </div>
            </div>
            <div class="step2-bottom">
                <router-link to="" class="link" @click.native="sendAnotherMail()">{{mailNotArrive}}</router-link>
                <router-link to="/login" class="link" >{{gobackToEasyTrade}}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import utils from "../../../util/utils";
    var _self;

    const _module='CTForget';
    const _common="ctError";
    const checkMailRule=(rule,value,callback)=>{
        _self.checkMail(rule,value,callback);
    }

    export default {
        name: 'register',
        components: {
        },
        data() {
            return {
                // step 1
                title:'Forget your password',
                emailLabel:"Email:",
                emailPlaceholder:"Please enter your email",
                noticetext:"Please enter your account email to receive the link of resetting your password.",

                buttonText:"Reset Password",
                noticeText1:"Always have a CIC Acount",
                loginText:"Log in",

                // step 2
                mailtitle:'Verify your email',
                mailNotifyContent:"A verify mail have been send to your email.",
                anOtherMailNotifyContent:"anOtherMailNotifyContent",
                clickinfo:"Click the link inside to get start",
                mailNotArrive:"Email didn't arrive",
                gobackToEasyTrade:'Go back to CIC Easy Trade >',

                // step3
                step3EmailContent:"Verify your email",
                step3NotifyContent:"You need to confirm you email address to start using you cic account",
                step3ButtonText:"Verify Your Email",
                step3NoticeText:"If you did not sign up for this account you can ignore this email",

                // 数据验证

                mailIsNull:"邮箱不能为空",
                mailIsInvalid:'邮箱格式不正确',
                mailIsTooLong:"邮箱超过50个字符",

                // 数据
                step:1,
                form:{
                    email:'',
                },

                rules: {
                    email:[{validator:checkMailRule,trigger:'blur'}]
                }
            }
        },
        mounted() {
            if (!utils.isPC()){
                this.$router.push({
                    path: '/forgetMobile',
                    query: this.$route.query
                })
                return
            }
            _self = this;
            this.title = this.$t(_module+'.title');
            this.emailLabel  = this.$t(_module+'.emailLabel');
            this.emailPlaceholder  = this.$t(_module+'.emailPlaceholder');
            this.noticetext  = this.$t(_module+'.noticetext');
            this.buttonText  = this.$t(_module+'.buttonText');
            this.noticeText1  = this.$t(_module+'.noticeText1');
            this.loginText  = this.$t(_module+'.loginText');
            this.mailtitle  = this.$t(_module+'.mailtitle');
            this.mailNotifyContent  = this.$t(_module+'.mailNotifyContent');
            this.anOtherMailNotifyContent  = this.$t(_module+'.anOtherMailNotifyContent');
            this.clickinfo  = this.$t(_module+'.clickinfo');
            this.mailNotArrive  = this.$t(_module+'.mailNotArrive');
            this.gobackToEasyTrade  = this.$t(_module+'.gobackToEasyTrade');
            this.step3EmailContent  = this.$t(_module+'.step3EmailContent');
            this.step3NotifyContent  = this.$t(_module+'.step3NotifyContent');
            this.step3ButtonText  = this.$t(_module+'.step3ButtonText');
            this.step3NoticeText  = this.$t(_module+'.step3NoticeText');
            this.mailIsNull  = this.$t(_module+'.mailIsNull');
            this.mailIsInvalid  = this.$t(_module+'.mailIsInvalid');
            this.mailIsTooLong = this.$t('CTRegister.mailIsTooLong');
        },
        methods:{
            checkMail (rule,value,callback){
                if(value==''){
                    callback(new Error(this.mailIsNull));
                } else {
                    if(value.length>50){
                        callback(new Error(this.mailIsTooLong));
                        return;
                    }
                    //var reg=/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                    // eslint-disable-next-line no-useless-escape
                    // let reg=/^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/;
                    if(!(value.indexOf('@')>0)){
                        callback(new Error(this.mailIsInvalid));
                    }else {
                        callback();
                    }
                }
            },
            doReset(){
                this.$refs.form.validate(valid => {
                    if (valid) {
                        _self.callSendMailApi();

                        //_self.step = 2;
                    } else {
                        if(_self.$showLog)
                        console.log('error submit!!')
                        return false
                    }
                })
            },
            callSendMailApi(){
                var params = new Object();
                params.email = this.form.email;
                this.axios.post('/registerct/norememberpwd',params).then(ret=>{
                    if(ret.status === 1){
                        _self.step = 2;
                    } else {
                        _self.$message.error(_self.$t(_common+'.'+ret.errorMsg));
                    }
                    if(_self.$showLog)
                    console.log(ret);
                }).catch(err=>{
                    if(_self.$showLog)
                    console.log(err);
                })

            },
            sendAnotherMail(){
                var params = new Object();
                params.email = this.form.email;
                this.axios.post('/registerct/norememberpwd',params).then(ret=>{
                    if(ret.status === 1){
                        _self.$message.success(_self.anOtherMailNotifyContent);
                        _self.mailNotifyContent = _self.anOtherMailNotifyContent;
                    } else {
                        _self.$message.error(_self.$t(_common+'.'+ret.errorMsg));
                    }
                    if(_self.$showLog)
                    console.log(ret);
                }).catch(err=>{
                    if(_self.$showLog)
                    console.log(err);
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "./index.less";
</style>
<style lang="less">
    .mainwindow {
        .el-main{
            height: 100%;
        }
        .emailForm .el-input__inner {
            height: 40px;
            line-height: 40px;
          padding-left: 60px;
        }
        .emailForm .el-input .el-input__clear{
            margin-top:5px;
        }
        .emailForm .el-form-item__label{
            text-align: left;
            color: #666666;
            line-height: 40px;
        }
        .emailForm .inputitem-right .el-form-item__content{
            margin-left: 10px !important;
        }

        .emailForm .el-checkbox__label{
            color: #666666;
        }
        .emailForm .el-checkbox__input.is-checked+.el-checkbox__label{
            color: #666666;
        }
        .emailForm .el-form-item{
            margin-bottom: 20px;
        }
      .emailForm .el-input__prefix{
        left: 17px;
        .iconfont{
          color: #345380;
          font-size: 20px;
        }
      }
    }

</style>
